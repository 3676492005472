<template>
  <div style="position: relative">
    <div v-if="$attrs.disabled === true" class="div-select disabled no-select" :class="elementClass">
      {{ selectedItemValue }}
      <font-awesome-icon icon="chevron-down" class="chevRonDown"/>
    </div>
    <div v-else :class="['div-select', 'no-select', 'cursor-pointer', elementClass, showDropdown === true ? 'active-custom-element' : '']" style="position: relative" @click.prevent.stop="showSelections">
      {{ selectedItemValue }}
      <font-awesome-icon icon="chevron-down" class="chevRonDown"/>
    </div>
    <div class="d-flex flex-column dropdown-menu active-custom-element p-0" v-if="items.length > 0 && showDropdown === true">
      <div class="p-0 m-0" v-if="includeSearchInput === true && items.length > 10 ">
        <input id = "searchValue" type="text" class="form-control form-control-sm active-custom-element" v-model="searchTerm" :placeholder="searchPlaceholder">
      </div>
      <div style="overflow-y: auto" id = "list-container" class="p-0 m-0">
        <a @mouseover="highLightList" v-for="item in itemsBySearchTerm" class="no-select select-with-search-item dropdown-item cursor-pointer item-list text-uppercase form-control-sm" :class="[selectedItem.id === item.id ? 'item-list-hover' : '', item.disabled === true ? 'disabled-list' : '']" @click="selectItem(item)" :data-disabled="item.disabled">{{ item.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export const EventBus = new Vue();
import selectSearchInstanceIdGeneratorMixin from "@/mixins/selectSearchInstanceIdGeneratorMixin";

export default {
  name : 'SelectWithSearch',
  mixins : [selectSearchInstanceIdGeneratorMixin],
  data() {
    return {
      selectedItem : {
        id : null,
        name : ''
      },
      showDropdown : false,
      searchTerm : ''
    }
  },
  methods : {
    highLightList() {
      $('.select-with-search-item.item-list-hover').removeClass('item-list-hover')
    },
    scrollInActiveList() {
      const activeList = $('.select-with-search-item.item-list-hover');
      const listParentElement = $('#list-container');
      const parentHeightCenter = listParentElement.height() / 2;
      if (activeList.length) {
        listParentElement.scrollTop(
          activeList.offset().top - (listParentElement.offset().top + listParentElement.scrollTop() + parentHeightCenter)
        );
      }
    },
    showSelections(event) {
      EventBus.$emit('hideDropdown', this.uniqueId);
      event.stopPropagation();
      this.searchTerm = '';
      const show = !this.showDropdown;

      let _this = this;
      if (show === true) {
        _this.showDropdown = show;

        setTimeout(() => {
          $('#searchValue').focus();
          _this.scrollInActiveList();
        }, 1);


      } else {
        this.showDropdown = show;
      }
    },
    selectItem(item) {
      if (item.disabled === true) {
        return;
      }
      this.selectedItem = item;
      this.showDropdown = false;
      this.searchTerm = '';
      this.$emit('input', item[this.keyItemValue]);
      this.$emit('change');
    },
    hideDropdown(uniqueId) {
      if (uniqueId !== this.uniqueId) {
        this.showDropdown = false;
      }
    }
  },
  props : {
    currentSelectedItemId : {
      type : [Number, null],
      default : null
    },
    defaultSelection : {
      type : String,
      default : ''
    },
    elementClass: {
      type : String,
      default : 'form-control form-control-sm text-uppercase'
    },
    items : {
      type : Array,
      default : []
    },
    keyItemValue : {
      type: String,
      default : 'id'
    },
    keyDisplayText : {
      type: String,
      default : 'name'
    },
    searchPlaceholder : {
      type: String,
      default : 'Type to search...'
    },
    includeSearchInput : {
      type : Boolean,
      default : true
    }
  },
  computed : {
    selectedItemValue() {
      const selected = this.items.filter(item => {
        return item[this.keyItemValue] === this.selectedItem?.[this.keyItemValue]
      });

      if (selected.length > 0) {
        return selected[0][this.keyDisplayText];
      }

      return '—— ALL ——';
    },
    itemsBySearchTerm() {
      let searchedItems = this.items.filter(item => item[this.keyDisplayText].toLowerCase().match(this.searchTerm.toLowerCase()));
      if (this.searchTerm.trim() === '') {
        return this.items;
      }
      return searchedItems;
    }
  },
  mounted() {
    this.selectedItem[this.keyItemValue] = this.currentSelectedItemId;
    $(document).on('click',(event) => {
      const elementTarget = $(event.target);
      const dataDisabled = $(elementTarget).attr('data-disabled');
      const targetId = elementTarget.attr('id');

      if (dataDisabled === 'true') {
        return;
      }

      if (this.showDropdown === true && targetId !== 'searchValue') {
        this.showDropdown = false;
      }
    });
  },
  created() {
    EventBus.$on('hideDropdown', this.hideDropdown);
  },
  beforeDestroy() {
    EventBus.$off('hideDropdown', this.hideDropdown);
  },

}
</script>

<style scoped>
.disabled, .disabled-list {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}


.chevRonDown {
  font-size: 12px;
  position: absolute;
  right: 6px;
  top: 9px;
  width: 9px;
}

.div-select {
  padding-left: 12px;
}

.show {
  display: block; /* Show when the dropdown is active */
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  width: 100%;
  //min-height: 100px; /* Set a minimum height */
  max-height: 250px; /* Set a maximum height if needed */
  overflow-y: auto; /* Enable vertical scrollbar */
}

.dropdown-item {
  padding-left: 12px;
  font-family: "Roboto", "Arial", sans-serif;
  color: #435464;
  font-weight: normal;
  display: block;
  min-height: 1.2em;
  white-space: nowrap
}

.item-list-hover:not(.disabled-list), .item-list:not(.disabled-list):hover {
  color: #fff;
  background-color: #0069d9; /* Original primary color */
  border-color: #0062cc;
}


</style>
