<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="" class="w-100">
        <div class="row">
          <div class="col-12 text-right">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancel">Cancel
              </button>
              <button class="e-btn e-btn-blue ml-2" type="submit" @click="submit" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" :disabled="hasChanges === false">Save</button>
            </template>
            <button class="e-btn e-btn-green ml-2" type="button" v-else @click="toEdit = true">Edit Internal Audit Report</button>

          </div>
        </div>

        <div class="mb-4">
          <h6 class="h6">Internal Audit Report</h6>
          <table class="w-100">
            <tr v-if="toEdit">
              <td style="width: 25%;">Audit Reference No.</td>
              <td style="width: 50%;">
                <input type="text" class="form-control form-control-sm w-auto"
                       v-model="internalAuditReport.reference_number"
                >
              </td>
              <td style="width: 12%;"></td>
              <td></td>
            </tr>
            <tr v-else>
              <td style="width: 25%;">Audit Reference No.</td>
              <td style="width: 50%;">
                {{ company_internal_audit_reports[$route.params.type]?.reference_number }}
              </td>
              <td style="width: 12%;"></td>
              <td></td>
            </tr>
          </table>
        </div>

        <div class="mb-4">
          <h6 class="h6">Audit Results</h6>
          <table class="w-100">
            <tr>
              <td style="width: 25%;">Non-Conformities</td>
              <td style="width: 50%;">{{ company_internal_audit_reports[$route.params.type]?.non_conformity_count }} (Objective Evidence: {{ company_internal_audit_reports[$route.params.type]?.objective_evidence_count }})</td>
            </tr>
            <tr>
              <td style="width: 25%;">Observation</td>
              <td style="width: 50%;"></td>
            </tr>
            <tr>
              <td style="width: 25%;">Rectified on the spot</td>
              <td style="width: 50%;">{{ company_internal_audit_reports[$route.params.type]?.rectified }}</td>
              <td style="width: 12%;"></td>
              <td></td>
            </tr>
          </table>
        </div>
        <table class="w-100 mb-3">
          <tr>
            <td style="width: 25%;"><b>Filled-up Attendance</b></td>
            <td style="width: 50%;" v-if="toEdit">
              <template>
                <input type="file" hidden ref="audit_attendance" accept=".doc,.docx,.pdf" @change="addAttendance"/>
                <button class="e-btn e-btn-green px-3" type="button" @click="$refs.audit_attendance.click()" v-if="attendanceData.file_path === null && hasNewAttendanceFile === false">
                  Add Attendance
                </button>
                <div v-else class="dropdown dropright">
                  <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                     id="actionDropdown"
                                     aria-expanded="false"
                                     aria-haspopup="true"
                                     data-toggle="dropdown"
                  ></font-awesome-icon>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                    <button class="dropdown-item text-secondary font-weight-bolder"
                            v-if="hasNewAttendanceFile === false"
                            @click="viewAttendance"
                    >
                      <font-awesome-icon icon="eye"/>
                      View
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="deleteAttendance"
                    >
                      <font-awesome-icon icon="trash" class="force-danger-all"

                      />
                      Delete
                    </button>
                  </div>
                </div>
              </template>
            </td>
            <td v-else>
              <div class="dropdown dropright">
                <font-awesome-icon icon="file-pdf" size="3x" class="force-danger-all" style="cursor: pointer"
                                   id="actionDropdown"
                                   aria-expanded="false"
                                   aria-haspopup="true"
                                   data-toggle="dropdown"
                                   v-if="attendanceData.file_path !== null"
                ></font-awesome-icon>
                <font-awesome-icon v-else icon="file-pdf" size="3x"
                ></font-awesome-icon>
                <div aria-labelledby="actionDropdown" class="dropdown-menu py-0">
                  <button class="dropdown-item text-secondary font-weight-bolder"
                          @click="viewAttendance"
                  >
                    <font-awesome-icon icon="eye"/>
                    View
                  </button>
                </div>
              </div>
            </td>
            <td style="width: 12%;"></td>
            <td></td>
          </tr>
        </table>
        <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>

        <template v-if="toEdit">
          <div class="mb-3">
            <h6 class="h6">Time Limit for Corrective Action Implementation</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">To be done not later than</td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_done_later_than"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Date</td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_time_limit_date"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  Director of Ship management office
                </td>
                <td style="width: 25%">
                  <template>
                    <select-with-search
                      :key="type + '_' + internalAuditReport.ca_ship_management_director_id"
                      v-model="internalAuditReport.ca_ship_management_director_id"
                      :current-selected-item-id="internalAuditReport.ca_ship_management_director_id"
                      :include-search-input="false"
                      :key-item-value="'id'"
                      :key-display-text="'name'"
                      :items="[{
                      id : null,
                      name : '-- Select Director of Ship Management Office --'
                    }].concat(director_security.sms)"
                      :element-class="'form-control w-auto form-control-sm'"
                    />
                  </template>
                </td>
                <td style="width: 25%"></td>
                <td style="width: 50%"></td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Corrective Action Implemented and reported by PIC</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date</td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_implemented_date"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Person Responsible</td>
                <td style="width: 25%">
                  <input type="text" class="form-control form-control-sm"
                         v-model="internalAuditReport.ca_person_responsible"
                  />
                </td>
                <td style="width: 25%"></td>
                <td style="width: 25%"></td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Confirmation For Corrective Action</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date</td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.ca_confirmation_date"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Audit Team Leader</td>
                <td style="width: 25%">
                  <select-with-search
                    :key="type + '_' + internalAuditReport.ca_confirmation_audit_leader"
                    v-model="internalAuditReport.ca_confirmation_audit_leader"
                    :current-selected-item-id="internalAuditReport.ca_confirmation_audit_leader"
                    :include-search-input="false"
                    :key-item-value="'id'"
                    :key-display-text="'name'"
                    :items="[{
                      id : null,
                      name : '-- Select Audit Team Leader --'
                    }].concat(audit_team_leaders)"
                    :element-class="'form-control w-auto form-control-sm'"
                  />
                </td>
                <td style="width: 50%"></td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Approval of Audit Completion</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date</td>
                <td>
                  <input type="date" class="form-control form-control-sm w-auto"
                         v-model="internalAuditReport.audit_approval_date"
                         :min="minimumDate"
                  >
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  Designated Person
                </td>
                <td style="width: 25%">
                  <template>
                    <select-with-search
                      :key="type + '_' + internalAuditReport.approval_ship_management_director_id"
                      v-model="internalAuditReport.approval_ship_management_director_id"
                      :current-selected-item-id="internalAuditReport.approval_ship_management_director_id"
                      :include-search-input="false"
                      :key-item-value="'id'"
                      :key-display-text="'name'"
                      :items="[{
                      id : null,
                      name : '-- Select Designated Person --'
                    }].concat(director_security.sms)"
                      :element-class="'form-control w-auto form-control-sm'"
                    />
                  </template>
                </td>
                <td style="width: 50%"></td>
              </tr>
            </table>
          </div>
        </template>
        <template v-else>
          <div class="mb-3">
            <h6 class="h6">Time Limit for Corrective Action Implementation</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">To be done not later than</td>
                <td>
                  {{ internalAuditReport.ca_done_later_than_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Date</td>
                <td>
                  {{ internalAuditReport.ca_time_limit_date_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  Director of Ship management office
                </td>
                <td>
                  {{ CaDirectorOrSecurity }}
                </td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Corrective Action Implemented and reported by PIC</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date</td>
                <td>
                  {{ internalAuditReport.ca_implemented_date_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Person Responsible</td>
                <td>
                  {{ internalAuditReport.ca_person_responsible }}
                </td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Confirmation For Corrective Action</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date</td>
                <td>
                  {{ internalAuditReport.ca_confirmation_date_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">Audit Team Leader</td>
                <td>
                  {{ audit_team_leaders.filter(lead => lead.id === internalAuditReport.ca_confirmation_audit_leader)[0]?.name }}
                </td>
              </tr>
            </table>
          </div>
          <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>
          <div class="mb-3">
            <h6 class="h6">Approval of Audit Completion</h6>
            <table class="w-100">
              <tr>
                <td style="width: 25%">Date</td>
                <td>
                  {{ internalAuditReport.audit_approval_date_human }}
                </td>
              </tr>
              <tr>
                <td style="width: 25%">
                  Designated Person
                </td>
                <td>
                  {{ approvalDirectoryOrSecurity }}
                </td>
              </tr>
            </table>
          </div>
        </template>
      </form>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import DateMixin from "@/mixins/DateMixin";
import CompanyAuditRecordMixin from "@/mixins/CompanyAuditRecordMixin";
import AuditorMixin from "@/mixins/AuditorMixin";
import {AlertService} from "@/services/AlertService";
import {DataService} from "@/services/DataService";
import {Form} from "form-backend-validation";
import SelectWithSearch from "@/components/common/SelectWithSearch.vue";

export default {
  name: 'CompanyInternalAuditReport',
  mixins : [DateMixin, CompanyAuditRecordMixin, AuditorMixin],
  components : {
    SelectWithSearch,
  },
  data() {
    return {
      calculatorSize : '2x',
      calculatorColor : '',
      toEdit : false,
      internalAuditReport : {
        id : null,
        reference_number : null,
        ca_done_later_than    :null,
        ca_time_limit_date : null,
        ca_ship_management_director_id : null,
        ca_company_officer_id : null,
        ca_company_cyber_officer_id : null,
        ca_implemented_date : null,
        ca_person_responsible : null,
        ca_confirmation_date : null,
        ca_confirmation_audit_leader : null,
        audit_approval_date : null,
        approval_ship_management_director_id : null,
        approval_company_officer_id : null,
        approval_company_cyber_officer_id : null,
      },
      attendanceData: {
        file : null,
        file_path : null,
        id_to_delete : null
      },
      showAddAttendanceButton : true,
      showVpiScoreModal : false,
      psc_score : null,
      icbt_score : null,
      averageScore : null,
    }
  },
  methods: {
    ...mapActions([
      'getCompanyInternalAudit',
      'updateCompanyInternalAuditReport',
      'getCompanyAuditRecordById',
      'getCompanyDirectorSecurity'
    ]),
    setPscAndIcbtScore(data) {
      this.icbt_score = data.icbt_score;
      this.psc_score = data.psc_score;
      this.averageScore = data.averageScore;
    },
    async cancel() {
      if (this.hasChanges === false) {
        this.toEdit = false;
        await this.initialize();
        return;
      }
      if (await AlertService.cancelAlert()) {
        this.toEdit = false;
        await this.initialize();
      }

    },
    async submit() {
      const param = Object.assign({}, this.internalAuditReport);
      if (this.attendanceData.file !== null) {
        param.audit_attendance = this.attendanceData.file;
      }

      delete param.attendance_url;
      delete param.audit_approval_date_human;
      delete param.ca_confirmation_date_human;
      delete param.ca_done_later_than_human;
      delete param.ca_time_limit_date_human;
      delete param.ca_implemented_date_human;

      let paramForm = new FormData();
      for (const key in param) {
        if (param[key] === null) {
          param[key] = '';
        } else {
          param[key] = typeof param[key] === 'string' ? param[key].trim() : param[key];
        }
        paramForm.append(key, param[key] === null ? '' : param[key]);
      }

      const updateResponse = await this.updateCompanyInternalAuditReport({
        id : param.id,
        form : paramForm
      });
      if (updateResponse.result === false) {
        return AlertService.errorAlert(updateResponse.message, 'UPDATING OF INTERNAL AUDIT REPORT FOR ' + this.$route.params.type.toUpperCase())
      }

      AlertService.successAlert('Updated successfully', 'UPDATING OF INTERNAL AUDIT REPORT FOR ' + this.$route.params.type.toUpperCase());
      this.toEdit = false;
      await this.initialize();

    },
    setInternalAudit() {
      this.internalAuditReport = {
        id : this.company_internal_audit_reports[this.$route.params.type].id,
        reference_number : this.company_internal_audit_reports[this.$route.params.type].reference_number,
        ca_done_later_than    : this.company_internal_audit_reports[this.$route.params.type].ca_done_later_than['date-picker'],
        ca_done_later_than_human    : this.company_internal_audit_reports[this.$route.params.type].ca_done_later_than.human,
        ca_time_limit_date : this.company_internal_audit_reports[this.$route.params.type].ca_time_limit_date['date-picker'],
        ca_time_limit_date_human : this.company_internal_audit_reports[this.$route.params.type].ca_time_limit_date.human,
        ca_ship_management_director_id : this.company_internal_audit_reports[this.$route.params.type].ca_ship_management_director_id,
        ca_company_officer_id : this.company_internal_audit_reports[this.$route.params.type].ca_company_officer_id,
        ca_company_cyber_officer_id : this.company_internal_audit_reports[this.$route.params.type].ca_company_cyber_officer_id,
        ca_implemented_date : this.company_internal_audit_reports[this.$route.params.type].ca_implemented_date['date-picker'],
        ca_implemented_date_human : this.company_internal_audit_reports[this.$route.params.type].ca_implemented_date.human,
        ca_person_responsible : this.company_internal_audit_reports[this.$route.params.type].ca_person_responsible,
        ca_confirmation_date : this.company_internal_audit_reports[this.$route.params.type].ca_confirmation_date['date-picker'],
        ca_confirmation_date_human : this.company_internal_audit_reports[this.$route.params.type].ca_confirmation_date.human,
        ca_confirmation_audit_leader : this.company_internal_audit_reports[this.$route.params.type].ca_confirmation_audit_leader,
        audit_approval_date : this.company_internal_audit_reports[this.$route.params.type].audit_approval_date['date-picker'],
        audit_approval_date_human : this.company_internal_audit_reports[this.$route.params.type].audit_approval_date.human,
        approval_ship_management_director_id : this.company_internal_audit_reports[this.$route.params.type].approval_ship_management_director_id,
        approval_company_officer_id : this.company_internal_audit_reports[this.$route.params.type].approval_company_officer_id,
        approval_company_cyber_officer_id : this.company_internal_audit_reports[this.$route.params.type].approval_company_cyber_officer_id,
      };

      if (this.company_internal_audit_reports[this.$route.params.type].audit_attendance !== null) {
        this.attendanceData.file_path = this.company_internal_audit_reports[this.$route.params.type].attendance_url;
      }
    },
    async initialize() {
      this.resetAttendanceData();
      await this.getCompanyInternalAudit({
        id : this.selectedCompanyAuditRecord[this.$route.params.type].id,
        type : this.$route.params.type
      });
      this.setInternalAudit();
    },
    addAttendance() {
      this.attendanceData.file = this.$refs.audit_attendance.files[0];
      this.showAddAttendanceButton = false;
      this.$refs.audit_attendance.value = null;
    },
    resetAttendanceData() {
      this.attendanceData = {
        file : null,
        file_path : null,
        id_to_delete : null,
      }
    },
    deleteAttendance() {
      this.resetAttendanceData();

      if (this.company_internal_audit_reports[this.$route.params.type].audit_attendance !== null) {
        this.attendanceData.id_to_delete = 1 // this is the id of the current attendance
      }
    },
    viewAttendance() {
      window.open(this.attendanceData.file_path,'_blank');
    }
  },
  async created() {
    await this.initialize();
    await this.getCompanyDirectorSecurity ();
    await this.getAuditTeamLeaders();
  },
  watch : {
    '$route.params.type'() {
      this.initialize();
    },
  },
  computed : {
    ...mapGetters([
      'company_internal_audit_reports',
      'director_security',
    ]),
    CaDirectorOrSecurity() {
      if (this.$route.params.type === 'sms') {
        return this.director_security.sms.filter(user => user.id === this.internalAuditReport.ca_ship_management_director_id)[0]?.name;
      }

      if (this.$route.params.type === 'isps') {
        return this.director_security.isps.filter(user => user.id === this.internalAuditReport.ca_company_officer_id)[0]?.name;
      }

      return this.director_security.cyber.filter(user => user.id === this.internalAuditReport.ca_company_cyber_officer_id)[0]?.name;
    },
    approvalDirectoryOrSecurity() {
      if (this.$route.params.type === 'sms') {
        return this.director_security.sms.filter(user => user.id === this.internalAuditReport.approval_ship_management_director_id)[0]?.name;
      }

      if (this.$route.params.type === 'isps') {
        return this.director_security.isps.filter(user => user.id === this.internalAuditReport.approval_company_officer_id)[0]?.name;
      }

      return this.director_security.cyber.filter(user => user.id === this.internalAuditReport.approval_company_cyber_officer_id)[0]?.name;
    },
    hasNewAttendanceFile() {
      return this.attendanceData.file !== null;
    },
    hasChanges() {
      const originalData = {
        reference_number : this.company_internal_audit_reports[this.$route.params.type].reference_number,
        ca_done_later_than : this.company_internal_audit_reports[this.$route.params.type].ca_done_later_than['date-picker'],
        ca_time_limit_date : this.company_internal_audit_reports[this.$route.params.type].ca_time_limit_date['date-picker'],
        ca_ship_management_director_id : this.company_internal_audit_reports[this.$route.params.type].ca_ship_management_director_id,
        ca_implemented_date : this.company_internal_audit_reports[this.$route.params.type].ca_implemented_date['date-picker'],
        ca_person_responsible : this.company_internal_audit_reports[this.$route.params.type].ca_person_responsible,
        ca_confirmation_date : this.company_internal_audit_reports[this.$route.params.type].ca_confirmation_date['date-picker'],
        ca_confirmation_audit_leader : this.company_internal_audit_reports[this.$route.params.type].ca_confirmation_audit_leader,
        audit_approval_date : this.company_internal_audit_reports[this.$route.params.type].audit_approval_date['date-picker'],
        approval_ship_management_director_id : this.company_internal_audit_reports[this.$route.params.type].approval_ship_management_director_id,
      }

      const editedData = {
        reference_number : this.internalAuditReport.reference_number,
        ca_done_later_than : this.internalAuditReport.ca_done_later_than,
        ca_time_limit_date : this.internalAuditReport.ca_time_limit_date,
        ca_ship_management_director_id : this.internalAuditReport.ca_ship_management_director_id,
        ca_implemented_date : this.internalAuditReport.ca_implemented_date,
        ca_person_responsible : this.internalAuditReport.ca_person_responsible,
        ca_confirmation_date : this.internalAuditReport.ca_confirmation_date,
        ca_confirmation_audit_leader : this.internalAuditReport.ca_confirmation_audit_leader,
        audit_approval_date : this.internalAuditReport.audit_approval_date,
        approval_ship_management_director_id : this.internalAuditReport.approval_ship_management_director_id,
      }

      const hasAttendanceDeletedId = this.attendanceData.id_to_delete !== null;

      const hasChanges = hasAttendanceDeletedId || this.hasNewAttendanceFile || DataService.checkIfChanged(originalData, editedData);
      // this.storeModuleIfChange(hasChanges, 'InternalAuditReport');
      return hasChanges;
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges() === true) {
        next();
      }
    } else {
      next();
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px;
}
</style>
