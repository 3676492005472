import {mapActions, mapGetters} from "vuex";
import en from "vue2-datepicker/locale/es/en";
import {DataService} from "@/services/DataService";

export default {
  data() {
    return {
      audit_main_types : [
        // index 0 means SMS
        {
          enabled : true,
          type : 'sms'
        },
        // index 1 means ISPS
        {
          enabled : false,
          type : 'isps'
        },
        // index 2 means CYBER
        {
          enabled : false,
          type : 'cyber'
        }
      ],
      type: 'sms',
      reportTypeStatusKeys : []
    }
  },
  methods : {
    ...mapActions([
      'getCompanyDetail',
      'getCompanyAuditRecordList',
      'getCompanyAuditRecordById',
      'deleteCompanyAuditRecord',
      'createCompanyAuditRecord',
      'updateCompanyAuditRecord',
      'addRequestKeys',
      'generateRequestKeys',
      'getCrewKeys'
    ]),
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getFirstLandingType(auditRecord) {
      const invalidValue = [null, undefined];

      if (invalidValue.includes(auditRecord.sms) === false) {
        return 'sms';
      }
      if (invalidValue.includes(auditRecord.isps) === false) {
        return 'isps';
      }
      return 'cyber';
    },
    getDataBasedOnActiveTypes(previousData) {
      let newObject = {};
      this.audit_main_types.forEach(auditType => {
        if (auditType.enabled === true) {
          newObject[auditType.type] = previousData[auditType.type];
        }
      });
      return newObject;
    },
    removeUnnecessaryKey(auditRecordByType, unnecessaryKeys) {
        for (const attribute in auditRecordByType) {
          if (unnecessaryKeys.includes(attribute) === true) {
            delete auditRecordByType[attribute];
          }
        }
      return auditRecordByType;
    },
    resetAuditMainTypes() {
      this.audit_main_types.forEach(type => {
        type.enabled = false;
      });
    },
    setTypeValues(data) {
      const auditTypes = DataService.assignObjectKeysToOtherObject(data, [
        'audit_type_2',
        'audit_type_3',
      ])

      const {leaders, members} = this.filterAuditorsByPosition(data?.auditors ?? []);
      return {
        report_number : data?.report_number ?? '',
        // audit_type_2 : auditTypes?.audit_type_2 ?? [],

        person_responsible : data?.person_responsible ?? null,
        audit_type_3 : auditTypes?.audit_type_3 ?? [],
        audit_date : [
          data?.audit_date_start['date-picker'] ?? null,
          data?.audit_date_end['date-picker'] ?? null
        ],
        audit_date_human : [
          data?.audit_date_start['human'] ?? null,
          data?.audit_date_end['human'] ?? null
        ],
        appointment_date : data?.appointment_date['date-picker'] ?? null,
        appointment_date_human : data?.appointment_date['human'] ?? null,
        audit_country_id : data?.country_id ?? null,
        audit_port_id : data?.port_id ?? null,
        lead_ids : leaders.map(leader => leader.id),
        leaders : leaders,
        members_ids :members.map(member => member.id),
        members :members,
        extension : data?.is_extended ?? null,
        extension_date_human : data?.extended_date['human'] ?? null,
        extension_date : data?.extended_date['date-picker'] ?? null,
        report_status_id : data?.report_status_id,
        audit_master : data?.audit_master ?? null,
        deck_dept_head : data?.deck_dept_head ?? null,
        eng_dept_head : data?.eng_dept_head ?? null
      };
    },
    filterAuditorsByPosition(auditors) {
      let filteredAuditors = {
        leaders : [],
        members : []
      };
      if (auditors.length > 0) {
        auditors.forEach((auditor) => {
          if (auditor.pivot.is_lead === 1) {
            filteredAuditors.leaders.push(auditor);
          } else {
            filteredAuditors.members.push(auditor);
          }
        });
      }
      return filteredAuditors;
    },
    addTypeInitialValues() {
      return {
        // audit_type_2 : [],
        audit_type_3 : [],
        person_responsible : '',
        audit_date : [null, null],
        appointment_date : null,
        audit_place : null,
        lead_ids : [],
        members_ids : [],
        extension : 0,
        extension_date : null,
        // same_in : {
        //   value : null,
        //   enabled : false
        // }
      };
    },
    getIndexOfType(type) {
      if (type === 'sms') {
        return 0;
      } else  if (type === 'isps') {
        return 1;
      }
      return 2;
    },
    getActiveTypes() {
      let activeTypes = [];

      this.audit_main_types.forEach(auditType => {
        if (auditType.enabled === true) {
          activeTypes.push(auditType.type);
        }
      });
      return activeTypes;
    },
    getRowSpanCount(data) {
      let activeKeys = [];
      this.audit_main_types.forEach(auditType => {
        if (data[auditType.type] !== null) {
          activeKeys.push(auditType.type);
        }
      });
      return activeKeys;
    },
    convertAuditType2IntoType(data) {
      let types = [];
      data.forEach(type2 => {
        if (type2 === 1) {
          types.push('Annual');
        } else if (type2 === 1) {
          types.push('Occasional');
        } else {
          types.push('Navigational Audit');
        }
      });
      return types.join(' & ');
    },
    convertAuditType3IntoType(data) {
      let types = [];
      data.forEach(type2 => {
        if (type2 === 1) {
          types.push('Onsite');
        } else {
          types.push('Remote');
        }
      });
      return types.join(' & ');
    },
    getTypeThatHasAnError(ErrorKeys) {
      let type = '';
      if (ErrorKeys.length > 0) {
        let splitKey = ErrorKeys[0].split('.');
        type = splitKey[0];
      }
      return type;
    },
    filterDataById(data, dataId) {
      return data.filter(newData => {
        return newData.id === dataId
      })
      .map(item => {
        return {
          name: item.name,
          locode_wrap: item.locode_wrap ?? ''
        }
      });
    },
    async setReportTypeStatusKeys(auditRecord) {
      const NEW = 1;
      const OPEN = 2;
      const CLOSED = 3;
      const READY_TO_CLOSE = 4;
      const RECORD_ONLY = 5;
      this.reportTypeStatusKeys = [];
      this.reportStatusKeys.forEach((statusKey) => {
        let newStatusKey = Object.assign({}, statusKey);
        if (auditRecord[this.type].report_status_id === NEW) {
          if (newStatusKey.id === NEW) {
            newStatusKey.disabled = true;
          }
          if (newStatusKey.id === READY_TO_CLOSE) {
            newStatusKey.disabled = this.isTypeReadyToClose;
          }
          if (newStatusKey.id === CLOSED) {
            newStatusKey.visible = false;
          }
        } else if (auditRecord[this.type].report_status_id === OPEN) {
          if (newStatusKey.id === NEW) {
            newStatusKey.visible = false;
          }

          if (newStatusKey.id === OPEN) {
            newStatusKey.disabled = true;
          }

          if (newStatusKey.id === READY_TO_CLOSE) {
            newStatusKey.disabled = !this.isTypeReadyToClose;
          }
          if (newStatusKey.id === CLOSED) {
            newStatusKey.visible = false;
          }
        } else if (auditRecord[this.type].report_status_id === READY_TO_CLOSE) {
          if (newStatusKey.id === NEW) {
            newStatusKey.visible = false;
          }
          if (newStatusKey.id === READY_TO_CLOSE) {
            newStatusKey.disabled = true;
          }
        } else if (auditRecord[this.type].report_status_id === CLOSED) {
          if (newStatusKey.id === NEW || newStatusKey.id === READY_TO_CLOSE) {
            newStatusKey.visible = false;
          }
          if (newStatusKey.id === OPEN) {
            newStatusKey.name = 'OPEN AGAIN';
          }

          if (newStatusKey.id === CLOSED) {
            newStatusKey.disabled = true;
          }
        } else {
          if (newStatusKey.id === NEW || newStatusKey.id === READY_TO_CLOSE || newStatusKey.id === CLOSED) {
            newStatusKey.visible = false;
          }
          if (newStatusKey.id === OPEN) {
            newStatusKey.name = 'OPEN AGAIN';
          }

          if (newStatusKey.id === RECORD_ONLY) {
            newStatusKey.disabled = true;
          }
        }
        this.reportTypeStatusKeys.push(newStatusKey);
      });
    },
    convertReportStatus(reportStatusId) {
      const filteredStatus = this.reportTypeStatusKeys.filter((reportStatus) => {
        return reportStatus.id === reportStatusId;
      });

      if (filteredStatus.length > 0) {
        return filteredStatus[0];
      }
      return this.reportStatusKeys[0] ?? [];
    },
  },
  computed : {
    ...mapGetters([
      'reportStatusKeys',
      'selectedCompanyAuditRecord',
      'companyDetail',
      'portKeys',
      'countryKeys',
      'companyAuditRecord',
    ]),
    en() {
      return en
    },
  },
  async created() {
    await this.addRequestKeys([
      'getPortKeys',
      'getCountryListKeys',
      'getReportStatusKeys',
      'getCompanyDetail',
    ]);
    await this.generateRequestKeys({});
  }
}
